<template>
  <div>
    <section class="mt-12 requests" v-if="upcomingTrips.length !== 0">
      <div v-for="(trip, index) in upcomingTrips" :key="index">
        <RequestTile  
          v-if="trip.hasOwnProperty('ta_budget')"
          :requestID="trip.id"
          :previousTab="tab"
          :destination="trip.trip_destination" :currency="trip.trip_currency"
          :budgetPerTraveler="trip.trip_budget" :numberOfTravelers="trip.trip_adults + trip.trip_children"
          :inProgress="trip.inProgress" :status="trip.trip_request_status"
          :class="index + 1 === upcomingTrips.length ? '' : 'mb-3'" icon-class="bxs-check-circle" />
        <TourTile v-else
          :previousTab="tab"
          :tourID="trip.id" 
          :destination="lang === 'en' ? trip.tour_info.destination : trip.tour_info.destination_fr" 
          :numberOfTravelers="totalTavellers(trip.other_travelers)"
          :price="trip.total_price"
          :currency="trip.currency"
        />
      </div>
    </section>

    <!-- When response is empty -->
    <EmptyState v-else :title="`${$t('my_trips.empty_state.upcoming_trip.title')}`" icon-class="bx bxs-plane-alt"
      :text="`${$t('my_trips.empty_state.upcoming_trip.description')}`" class="mt-20" />
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import RequestTile from "@/components/tiles/RequestTile";
import TourTile from "@/components/tiles/TourTile";
import { mapGetters } from "vuex";

export default {
  components: { EmptyState, RequestTile, TourTile },
  data() {
    return {
      lang: localStorage.getItem("lang"),
      tab: "",
    };
  },
  computed: {
    ...mapGetters("user", ["upcomingTrips"]),
  },
  methods: {
    totalTavellers(otherTravellers) {
      let others = JSON.parse(otherTravellers);
      return 1 + others.length;
    }
  },
  mounted() {
    if (this.$route.query["tab"] !== undefined) {
      this.tab = this.$route.query["tab"];
    }
  }
};
</script>
