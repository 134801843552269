<template>
    <router-link tag="a" :to="tourPath" class="tile">
            <div class="description">
              <div :class="['status inline-block sm:hidden', statusStyle]">
                <i :class="['bx', iconClass]"></i><span>{{ statusText }}</span>
              </div>
              <h3 class="tile-title">{{ destination }}</h3>
              <p class="font-sans text-sm text-black-lighter">
                {{ $tc("tiles.request.travelers", numberOfTravelers, {
                                                count: numberOfTravelers,
                                              })
                                            }}
                · {{ totalPrice }} 
              </p>
            </div>
            <i class="bx bx-chevron-right bx-sm"></i>
          </router-link>
</template>
<script>
export default {
    name: "TourTile",
    data() {
        return {
            region: "en-US",
        };
    },
    props: {
        previousTab: {
            type: String,
            default: "",
        },
        destination: {
            type: String,
            default: "Country of destinations",
        },
        numberOfTravelers: {
            type: Number,
            default: 0,
        },
        currency: {
            type: String,
            default: "USD",
        },
        price: {
            type: Number,
            default: 0,
        },
        status: {
            type: String,
            default: "completed",
        },
        inProgress: {
            type: Boolean,
            default: false,
        },
        iconClass: {
            type: String,
            default: "bxs-time",
        },
        tourID: { type: Number, default: 1 },
    },
    computed: {
        tourPath() {
            let previousTab = "";
            if (this.previousTab.length > 0) {
                previousTab = `&previous_tab=${this.previousTab}`;
            }
            return `/account/trips/tour?id=${this.tourID}${previousTab}`;
        },
        statusStyle() {
            if (
                this.status === "open" ||
                this.status === "changes" ||
                this.status === "draft"
            ) {
                return "status-in-progress";
            }
            if (this.status === "completed") {
                return "status-booked";
            }
            if (this.status === "cancelled") {
                return "status-cancelled";
            }
            return "status-available";
        },
        statusText() {
            let text;
            if (
                this.status === "open" ||
                this.status === "changes" ||
                this.status === "draft"
            ) {
                //In progress
                text = this.$t("tiles.request.status.status_1");
            }
            if (this.status == "pending") {
                //Offer available
                text = this.$t("tiles.request.status.status_2");
            }
            if (this.status == "completed") {
                //Booked
                text = this.$t("tiles.request.status.status_3");
            }
            if (this.status == "cancelled") {
                //Cancelled
                text = this.$t("tiles.request.status.status_4");
            }
            return text;
        },
        totalPrice() {
            return this.formatToCurrency(this.price, this.currency);
        },
    },
    methods: {
        formatToCurrency(value, currency) {
            value = new Intl.NumberFormat(this.region, {
                style: "currency",
                currency: currency || "USD",
            }).format(value);
            return value;
        },
    },
};
</script>
<style scoped>
.tile {
    @apply flex items-center justify-between flex-wrap;
    @apply w-full py-4 border-b border-grey-base;
}

.title-title {
    @apply font-sans font-semibold text-black-base;
}

.status {
    @apply text-xxs py-1 mt-1 font-semibold uppercase;
    @apply inline-flex items-center;
}

.status i {
    @apply text-sm mr-1;
}

.status-in-progress {
    @apply text-yellow-dark;
}
.status-available {
    @apply text-teal-dark;
}
.status-booked {
    @apply text-green-dark;
}
.status-cancelled {
    @apply text-red-dark;
}
</style>
