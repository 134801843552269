<template>

    <div role="status" class="skeleton">
        <div class="skeleton-head-row">
            <div class="skeleton-headline"></div>
            <div class="skeleton-headline sm"></div>
        </div>
        <div class="skeleton-details-container">
            <div class="skeleton-details wider"></div>
            <div class="skeleton-details"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SkeletonTripRequest"
}
</script>
<style scoped>
.skeleton {
    @apply animate-pulse flex flex-col gap-y-4 flex-wrap;
}

.skeleton-head-row {
    @apply flex justify-between w-full;
}

.skeleton-headline {
    @apply bg-grey-dark h-2 rounded-full;
    @apply w-24;
}

.sm {
    @apply w-12;
}

.skeleton-details-container{
    @apply flex flex-col gap-4;
}

.skeleton-details {
    @apply h-2 bg-grey-base rounded-full;
    @apply w-52;
}

.wider {
    @apply w-64;
}
</style>