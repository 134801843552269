<template>
  <div>
    <section class="mt-12 requests" v-if="cancelledTrips.length != 0">
      <RequestTile
        v-for="(request, index) in cancelledTrips"
        :key="index"
        :previousTab="tab"
        :requestID="request.id"
        :destination="request.trip_destination"
        :currency="request.trip_currency"
        :budgetPerTraveler="request.trip_budget"
        :numberOfTravelers="request.trip_adults + request.trip_children"
        :inProgress="request.inProgress"
        :status="request.trip_request_status"
        :class="index + 1 == cancelledTrips.length ? '' : 'mb-3'"
        icon-class="bxs-x-circle"
      />
    </section>

    <!-- When response is empty -->
    <EmptyState
      v-else
      :title="`${$t('my_trips.empty_state.cancelled_trip.title')}`"
      icon-class="bx bx-check"
      :text="`${$t('my_trips.empty_state.cancelled_trip.description')}`"
      class="mt-20"
    />
  </div>
</template>

<script>
import EmptyState from '@/components/EmptyState';
import RequestTile from '@/components/tiles/RequestTile';
import { mapGetters } from 'vuex';

export default {
  components: { RequestTile, EmptyState },
  data() {
    return {
      tab: "",
    };
  },
  computed: {
    ...mapGetters('user', ['cancelledTrips']),
  },
  methods: {},
  mounted() {
    if (this.$route.query["tab"] !== undefined) {
      this.tab = this.$route.query["tab"];
    }
  }
};
</script>
