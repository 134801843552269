<template>
  <div>
    <Navbar mode="solid" class="fixed top-0 left-0 z-10" />

    <main class="pb-32 mt-20">
      <div class="wrapper">
        <h1 class="mb-8 font-sans text-2xl font-bold text-black-base">
          {{ $t("my_trips.title") }}
        </h1>
        <div class="skeleton-loader" v-if="loading">
          <SkeletonTabs class="mb-12" />
          <SkeletonTripRequest />
          <Divider class="my-8" />
          <SkeletonTripRequest />
          <Divider class="my-8" />
          <SkeletonTripRequest />
        </div>
        <template v-else>
          <section class="empty-state no-requests" v-if="!trips">
            <EmptyState :title="$t('my_trips.empty_state.trip_offer.title')"
              :text="$t('my_trips.empty_state.trip_offer.description')"
              :btnText="$t('my_trips.empty_state.trip_offer.button')" icon-class="bx bxs-plane-alt" :useLink="true"
              linkPath="/destinations" />
          </section>

          <template v-else>
            <Tab :tabItems="tripsTabSections" />

            <pending-trips v-if="tripsTabSections[0].isActive"></pending-trips>
            <upcoming-trips v-if="tripsTabSections[1].isActive">
            </upcoming-trips>
            <cancelled-trips v-if="tripsTabSections[2].isActive"></cancelled-trips>
          </template>
        </template>
      </div>
    </main>
    <BottomBar class="layout-bar" />
    <Footer />
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import Tab from "@/components/tabs/Tab";
import PendingTrips from "./PendingTrips";
import UpcomingTrips from "./UpcomingTrips";
import CancelledTrips from "./CancelledTrips.vue";
import { mapActions, mapGetters } from "vuex";
import BottomBar from "@/components/layout/BottomBar";
import SkeletonTripRequest from "@/components/skeletons/SkeletonTripRequest.vue";
import SkeletonTabs from "@/components/skeletons/SkeletonTabs.vue";
import Divider from "@/components/dividers/Divider.vue";
export default {
  name: "Account",
  components: {
    BottomBar,
    EmptyState,
    PendingTrips,
    UpcomingTrips,
    CancelledTrips,
    Tab,
    SkeletonTripRequest,
    SkeletonTabs,
    Divider,
  },
  data() {
    return {
      tripsTabSections: [
        {
          name: this.$t("my_trips.tabs.item_1"),
          isActive: true,
        },
        {
          name: this.$t("my_trips.tabs.item_2"),
          isActive: false,
        },
        {
          name: this.$t("my_trips.tabs.item_3"),
          isActive: false,
        },
      ],
      currentTag: undefined,
      loading: false,
    };
  },
  watch: {
    "requestTab.isActive"(newVal) {
      if (newVal === true) {
        if (this.$route.query.tab !== "request")
          this.$router.push({ query: { tab: "request" } });
      }
    },
    "upcomingTab.isActive"(newVal) {
      if (newVal === true) {
        if (this.$route.query.tab !== "upcoming")
          this.$router.push({ query: { tab: "upcoming" } });
      }
    },
    "cancelledTab.isActive"(newVal) {
      if (newVal === true) {
        if (this.$route.query.tab !== "cancelled")
          this.$router.push({ query: { tab: "cancelled" } });
      }
    },
  },
  computed: {
    ...mapGetters("user", ["trips"]),
    requestTab() {
      return this.tripsTabSections[0];
    },
    upcomingTab() {
      return this.tripsTabSections[1];
    },
    cancelledTab() {
      return this.tripsTabSections[2];
    },
  },
  methods: {
    ...mapActions("user", ["getTripRequest", "getTour"]),
    async getTrip() {
      this.loading = true;
      await this.getTripRequest();
      await this.getTour();
      this.loading = false;
    },
    setAllTabsToFalse() {
      this.tripsTabSections.forEach((tab) => {
        tab.isActive = false;
      });
    },
    goToRequest() {
      this.$router.push("/request");
    },
  },
  async mounted() {
    //Set tabs
    if (this.$route.query["tab"]) {
      this.setAllTabsToFalse();
      if (this.$route.query["tab"] === "request") {
        this.tripsTabSections[0].isActive = true;
      }

      if (this.$route.query["tab"] === "upcoming") {
        this.tripsTabSections[1].isActive = true;
      }

      if (this.$route.query["tab"] === "cancelled") {
        this.tripsTabSections[2].isActive = true;
      }
    }

    await this.getTrip();
  },
};
</script>

<style scoped>
.wrapper {
  @apply relative w-full px-4 pt-12 transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden z-20;
}
</style>
