<template>

    <div role="status" class="skeleton">
        <div class="skeleton-tab-item" v-for="i in number" :key="i"></div>
    </div>
</template>
<script>
export default {
    name: "SkeletonTabs",
    props: {
        number: {
            type: Number,
            default: 3,
        }
    }
}
</script>
<style scoped>
.skeleton {
    @apply animate-pulse flex flex-row gap-x-4;
}

.skeleton-tab-item {
    @apply bg-grey-dark h-2 rounded-full;
    @apply w-24;
}
</style>