<template>
  <div>
    <section class="mt-12 requests" v-if="sortedTrips.length !== 0">
      <RequestTile
        v-for="(request, index) in sortedTrips"
        :key="index"
        :previousTab="tab"
        :requestID="request.id"
        :destination="request.trip_destination"
        :currency="request.trip_currency"
        :budgetPerTraveler="request.trip_budget"
        :numberOfTravelers="request.trip_adults + request.trip_children"
        :inProgress="request.inProgress"
        :status="request.trip_request_status"
        :class="index + 1 === sortedTrips.length ? '' : 'mb-3'"
      />
    </section>
    <section class="mt-12 requests" v-else>
      <!-- When response is empty -->
        <EmptyState
          :title="`${$t('my_trips.empty_state.trip_offer.title')}`"
          faIconClass="fal fa-check-circle"
          :text="`${$t('my_trips.empty_state.trip_offer.description')}`"
          class="mt-20"
          :use-link="true"
          link-path="/destinations"
          :btn-text="`${$t('my_trips.empty_state.trip_offer.button')}`"
        />
    </section>
  </div>
</template>

<script>
import RequestTile from "@/components/tiles/RequestTile";
import { mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState.vue";
export default {
  components: {
    RequestTile,
    EmptyState
  },
  data() {
    return {
      tab: "",
    };
  },
  computed: {
    ...mapGetters("user", ["pendingTrips"]),
    sortedTrips() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.pendingTrips.sort(function (x, y) {
        if (x.trip_request_status < y.trip_request_status) {
          return 1;
        }
        if (x.trip_request_status > y.trip_request_status) {
          return -1;
        }
        return 0;
      });
    },
  },
  mounted() {
    if (this.$route.query["tab"] !== undefined) {
      this.tab = this.$route.query["tab"];
    }
  }
};
</script>
