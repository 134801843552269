import { render, staticRenderFns } from "./SkeletonTripRequest.vue?vue&type=template&id=1023d3ce&scoped=true"
import script from "./SkeletonTripRequest.vue?vue&type=script&lang=js"
export * from "./SkeletonTripRequest.vue?vue&type=script&lang=js"
import style0 from "./SkeletonTripRequest.vue?vue&type=style&index=0&id=1023d3ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1023d3ce",
  null
  
)

export default component.exports